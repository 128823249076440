import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { getImageFolder } from '../../functions/utils';

const AboutPartership = (props: { partnershipTitle: string, partners: any[]}) => {

    const chosenByFolder: any[] = getImageFolder('chosen-by/');
    const whiteLogos: any[] = chosenByFolder.filter((el: any) => el?.node && el.node?.relativePath && (el.node.relativePath as string).includes('white') || null);

    return (
        <>
            <div className='flex items-center justify-center max-h-screen lg:min-h-screen'>
                <div className='flex flex-col w-full h-96 lg:h-full'>
                    <div className='flex items-center w-full h-2/3'>
                        <div className='flex flex-col items-start justify-center w-full h-full text-3xl md:font-bold md:text-4xl lg:text-5xl xl:text-7xl'>
                            {props.partnershipTitle}
                        </div>
                    </div>
                    <div className='flex flex-row w-full pt-6 lg:items-center h-1/2 lg:h-1/3 lg:pt-32'>
                        {
                            props?.partners && props.partners.map((el: any, index: number) => {
                                const _partnerTitle: string = el.partnerTitle.toLowerCase();
                                const _partnerDescription: string = el.partnerDescription.toLowerCase();
                                const partnerLogo: any = whiteLogos.filter((el: any) => el.node && el.node.relativePath && (el.node.relativePath as string).includes(_partnerTitle) || null)[0];
                                const image: IGatsbyImageData = partnerLogo?.node && getImage(partnerLogo?.node);
                                return (
                                    <div className={`flex flex-col items-center w-1/${props.partners.length} h-full px-4 lg:px-0 pt-6 leading-5 lg:items-start`} key={index}>
                                        <GatsbyImage image={image} alt={'logo' + index} objectFit="contain" className='flex justify-start h-full lg:h-5'/>
                                        <div className='hidden w-3/5 lg:pt-16 lg:flex'>
                                            {_partnerDescription}
                                        </div>
                                    </div>
                                )
                            })
                        }                            
                    </div>
                </div>
            </div>
        </>
    )

}

export default AboutPartership;